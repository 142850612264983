import * as React from "react";
import { Link } from "gatsby";

// import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Img from "gatsby-image";
import Layout from "../../components/Layout";
import Verse from "../../components/Verse.js";
import { graphql } from "gatsby";

export default function KeepItLooseKeepItTight({ data }) {
  return (
    <Layout>
      <Helmet title="Keep It Loose, Keep it Tight | Amos Lee" />

      <h1>Keep It Loose, Keep it Tight</h1>

      <h2>Amos Lee</h2>

      <h4>Key: Am</h4>

      <h4>Tempo: 92</h4>

      <Link to="/">List</Link>

      <Img fluid={data.file.childImageSharp.fluid} />

      <Columns columns="2">
        <Verse>
          <p>Well, I walked over the bridge</p>
          <p>Into the city where I live</p>
          <p>And I saw my old landlord</p>
          <p>Well, we both said, "Hello"</p>
          <p>There were nowhere else to go</p>
          <p>'Cause his rent I couldn't afford</p>
        </Verse>
        <Verse>
          <p>Well, relationships change</p>
          <p>Oh, I think it's kinda strange</p>
          <p>How money makes a man grow</p>
          <p>Oh, some people they claim</p>
          <p>If you get enough fame</p>
          <p>You live over the rainbow</p>
          <p>Over the rainbow</p>
        </Verse>
        <Verse>
          <p>But the people on the street</p>
          <p>Out on buses or on feet</p>
          <p>We all got the same blood flow</p>
          <p>Oh, in society</p>
          <p>Every dollar got a deed</p>
          <p>We all need a place so we can go</p>
          <p>And feel over the rainbow</p>
        </Verse>
        <Chorus>
          <p>But sometimes</p>
          <p>We forget what we got</p>
          <p>Who we are</p>
          <p>Oh, who we are not</p>
          <p>I think we got a chance</p>
          <p>To make it right</p>
          <p>Keep it loose</p>
          <p>Keep it tight</p>
          <p>Keep it tight</p>
        </Chorus>
        <Verse>
          <p>I'm in love with a girl</p>
          <p>Who's in love with the world</p>
          <p>Though I can't help but follow</p>
          <p>Though I know someday</p>
          <p>She is bound to go away</p>
          <p>And stay over the rainbow</p>
          <p>Gotta learn how to let her go</p>
          <p>Over the rainbow</p>
        </Verse>
        <Chorus>
          <p>Sometimes we forget who we got</p>
          <p>Who they are</p>
          <p>Oh, and who they are not</p>
          <p>There is so much more in love</p>
          <p>Than black and white</p>
          <p>Keep it loose child</p>
          <p>Gotta keep it tight</p>
          <p>Keep it loose child</p>
          <p>Keep it tight</p>
        </Chorus>
        <Chorus>
          <p>Keep it tight</p>
          <p>Keep it tight</p>
          <p>Yeah</p>
        </Chorus>
      </Columns>
    </Layout>
  );
}

export const query = graphql`
  query {
    file(relativePath: { eq: "keep-it-loose-keep-it-tight.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`;